/*
  Project: Merkanta
  Author: Lubos Kmetko
 */

require('lazysizes');
require('./Header')();
require('./Visibility')();

import Tabs from './Tabs';
import Lightbox from './Lightbox';

new Tabs();
new Lightbox();
