/**
 * Main navigation trigger
 */

'use strict';

module.exports = function() {

  var header = document.querySelector(".js-header");
  var openBtn = document.querySelector(".js-header-open");
  var closeBtn = document.querySelector(".js-header-close");

  if (header && openBtn && closeBtn) {
    openBtn.addEventListener("click", function (e) {
      header.classList.add('is-open');
      e.preventDefault();
    });

    closeBtn.addEventListener("click", function (e) {
      header.classList.remove('is-open');
      e.preventDefault();
    });
  }
};
