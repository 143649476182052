import { forEach } from 'iterall';

class Tabs {
  constructor() {
    this.el = document.querySelector('.js-tabs');
    this.tabContentContainers = this.el ? this.el.querySelectorAll('.js-tab') : null;
    this.tabNavigationLinks = this.el ? this.el.querySelectorAll('.js-tabs-nav') : null;

    this.state = {
      activeIndex: 0,
      initCalled: false,
    };

    this.init();
  }

  init() {
    if (this.el === null) {
      return;
    }

    if (!this.state.initCalled) {
      this.setState({ initCalled: true });

      forEach(this.tabContentContainers, (item, i) => this.hideTabs(item, i));
      forEach(this.tabNavigationLinks, (item, i) => this.handleClick(item, i));
      this.tabNavigationLinks[this.state.activeIndex].classList.add('is-active');
    }
  }

  handleClick(link, index) {
    link.addEventListener('click', event => {
      event.preventDefault();
      this.goToTab(index);
    });
  }

  goToTab(index) {
    const { activeIndex } = this.state;
    if (this.isValidIndex(index)) {
      this.tabNavigationLinks[activeIndex].classList.remove('is-active');
      this.tabNavigationLinks[index].classList.add('is-active');
      this.tabContentContainers[activeIndex].classList.add('is-hidden');
      this.tabContentContainers[index].classList.remove('is-hidden');
      this.setState({ activeIndex: index });
    }
  }

  hideTabs(tab, index) {
    const { activeIndex } = this.state;
    if (index !== activeIndex) {
      this.tabContentContainers[index].classList.add('is-hidden');
    }
  }

  setState(newState) {
    this.state = Object.assign({}, this.state, newState);
  }

  isValidIndex(index) {
    return index !== this.state.activeIndex && index >= 0 && index <= this.tabNavigationLinks.length
  }
}

export default Tabs;
