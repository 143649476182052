/**
 * Detects visibility of elements entering viewport
 * and adds is-visible class which can be used to fire up animations in CSS
 *
 * Use class js-visible on elements you want to detect.
 * Data attribute offset defines an offset point in pixels at which element is detected.
 * Negative values can be used to "postpone" detection.
 *
 * @example
 * <div class="js-visible" data-offset="50">
 */

'use strict';

var inViewport = require('in-viewport');

module.exports = function() {

  detectVisibility('.js-visible');

  /**
   * Detects when element is visible in viewport
   * @param  {string} selector Element selector
   * @param  {number} offset  Offset parameter in px for element detection
   */
  function detectVisibility(selector) {

    var elementList = document.querySelectorAll(selector);

    if (elementList.length === 0) {
      return;
    }

    for (var i = 0; i < elementList.length; i++) {
      var offset = elementList[i].dataset.offset * 1;

      if (offset === undefined) {
        offset = 0;
      }

      inViewport(elementList[i], { offset: offset }, markVisible);
    }
  }

  /**
   * Mark element as visible by adding is-visible class
   * @param  {object} element Element object
   */
  function markVisible(element) {
    element.classList.add("is-visible");
  }
};
