
class LightBox {
  constructor() {
    this.lightBox = require('baguettebox.js');
    this.init();
  }

  init() {
    this.lightBox.run('.js-gallery');
  }
}

export default LightBox;
